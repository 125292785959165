emailjs.init('a_amVvEMPvewlfWET');

document
  .getElementById('connectUsForm')
  .addEventListener('submit', function (event) {
    event.preventDefault();
    console.log('event', event);

    const name = document.querySelector('input[name="order_name"]').value;
    const phone = document.querySelector('input[name="order_phone"]').value;

    const email = document.querySelector('input[name="order_email"]').value;
    const message = document.querySelector(
      'textarea[name="order_message"]',
    ).value;

    const modal = document.getElementById('modal');

    const loader = document.querySelector('.loader');
    loader.style.display = 'block';

    const serviceID = 'service_a4tw48d';
    const templateID = 'template_lms0s1o';

    const emailParams = {
      to_email: 'info@tcr-smartservices.com',
      from_name: 'TCR Smart creation services',
      message: `
        Name: ${name}
        Phone: ${phone}
        Email: ${email}
        Message: ${message}`,
    };

    emailjs.send(serviceID, templateID, emailParams).then(
      function (response) {
        console.log('Письмо успешно отправлено!', response);

        document.getElementById('connectUsForm').reset();
        modal.style.display = 'none';
        document.body.style.overflow = 'auto';
        loader.style.display = 'none';
      },
      function (error) {
        console.error('Произошла ошибка при отправке письма:', error);
      },
    );
  });
